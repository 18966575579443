import Image1 from './images/items/item1.png';
import Image2 from './images/items/item2.png';
import Image3 from './images/items/item3.png';
import Image4 from './images/items/item4.png';
import Image5 from './images/items/item5.png';
import Image6 from './images/items/item6.png';
import Image7 from './images/items/item7.png';

export const CATEGORIES = [
	{
		id: 1,
		name: 'Для дома',
		open: false,
	},
	{
		id: 2,
		name: 'Для офиса',
		open: false,
	},
	{
		id: 3,
		name: 'Для взрослых',
		open: false,
	},
	{
		id: 4,
		name: 'Для детей',
		open: false,
	}
];

export const items = [
	{
		id: 1,
		image: Image1,
		name: 'Drago',
		fullName: 'Средство инсектицидное "Жидкость от комаров" Drago',
		packaging: '30 мл',
		capacity: 20,
		price: 539,
		count: 0,
		category: 1,
	},
	{
		id: 2,
		image: Image2,
		name: 'Drago',
		fullName: 'Средство инсектицидное "Жидкость от комаров". Комплект: электрофумигатор + жидкость от комаров Drago 30 мл',
		packaging: '1шт',
		capacity: 12,
		price: 1006,
		count: 0,
		category: 1,
	},
	{
		id: 3,
		image: Image3,
		name: 'Drago',
		fullName: 'Средство инсектицидное "Пластины от комаров" Drago 10 шт',
		packaging: '1шт',
		capacity: 200,
		price: 184,
		count: 0,
		category: 1,
	},
	{
		id: 4,
		image: Image4,
		name: 'Drago',
		fullName: 'Средство инсектицидное «Спираль от комаров – эффект» Drago (10 спиралей)',
		packaging: '1шт',
		capacity: 80,
		price: 597,
		count: 0,
		category: 4,
	},
	{
		id: 5,
		image: Image5,
		name: 'Drago FAMILY',
		fullName: 'Средство репеллентное «Drago FAMILY» (спрей 100мл)',
		packaging: '100мл',
		capacity: 30,
		price: 969,
		count: 0,
		category: 2,
	},
	{
		id: 6,
		image: Image6,
		name: 'Drago EXTREME',
		fullName: 'Средство репеллентное «Drago EXTREME» (спрей 100 мл)',
		packaging: '100мл',
		capacity: 30,
		price: 1195,
		count: 0,
		category: 3,
	},
	{
		id: 7,
		image: Image7,
		name: 'Drago KIDS',
		fullName: 'Средство репеллентное «Drago KIDS»',
		packaging: '85мл',
		capacity: 30,
		price: 1102,
		count: 0,
		category: 4,
	}
];