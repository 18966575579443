import React, { useState } from 'react';
import { Modal } from './Modal';

export const Card = ({ item, handleItemCountChange }) => {
	const [modalOpen, setModalOpen] = useState(false);

	const handleAddClick = () => {
		handleItemCountChange(item, 1)
	};

	const handleRemoveClick = () => {
		handleItemCountChange(item, -1);
	};

	return (
		<>
			<Modal open={modalOpen} handleClose={() => setModalOpen(false)}>
				<div className="modal-item-image">
					<img src={item.image} alt={item.name} />
				</div>
				<div className="modal-item-name">{item.fullName}</div>
				<div className="modal-item-packaging">Фасовка: {item.packaging}</div>
				<div className="modal-item-capacity">Количество в упаковке: {item.capacity}шт.</div>
			</Modal>
			<div className='item-card-container'>
				{item.count > 0 && <div className="item-card-count">{item.count}</div>}
				<div className="item-card-image-wrapper" onClick={() => setModalOpen(true)}>
					<img src={item.image} alt={item.name} className="item-card-image"/>
				</div>
				<div className='item-card-title'>
					<span className="item-card-name">{item.name} ({item.packaging})</span>
					<span className="item-card-price">{item.price}₸</span>
				</div>
				{item.count === 0 ?
					<button className='item-card-button' onClick={handleAddClick}>Добавить</button> :
					<div className='item-card-toggle-qty'>
						<span className="item-card-toggle-remove" onClick={handleRemoveClick}>-</span>
						<span className="item-card-toggle-add" onClick={handleAddClick}>+</span>
					</div>
				}
			</div>
		</>
	);
};