import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from '../components/Modal';
import axios from 'axios';

import InputMask from 'react-input-mask';

export const Cart = ({ items, handlePayButton, changePage }) => {
	const addressRef = useRef();
	const phoneRef = useRef();
	const commentRef = useRef();

	const [modalOpen, setModalOpen] = useState(false);

	const totalPrice = useMemo(() => {
		let total = 0;
		items.forEach(item => {
			total += item.count * item.price;
		})
		return total;
	}, [items]);

	const handleTelegramButtonClick = async () => {
		if (!phoneRef.current.value.length) {
			const phoneNumberContainer = document.getElementById('phone-number-container');
			phoneNumberContainer.style.border = '1px solid red';
			setTimeout(() => {
				phoneNumberContainer.style.border = 'none';
			}, 2000);
			return;
		}
		if (!addressRef.current.value.length) {
			addressRef.current.style.border = '1px solid red';
			setTimeout(() => {
				addressRef.current.style.border = 'none';
			}, 2000);
			return;
		}
		const data = {
			phoneNumber: phoneRef.current.value,
			comment: commentRef.current.value,
			address: addressRef.current.value,
			orders: items.map(item => ({ productName: item.name, amount: item.count, price: item.price })),
			userId: window.Telegram.WebApp.initDataUnsafe.query_id
		};
		axios
			.post('https://shop.nomad4ce.com/api/v1/teleshop/order/', data)
			.then(() => setModalOpen(true))
			.catch(() => {});
	};

	useEffect(() => {
		const telegramMainButton = window.Telegram.WebApp.MainButton;
		telegramMainButton.setParams({
			text: `Отправить заявку на стоимость ${totalPrice}₸`,
			color: '#f9a818',
			text_color: '#fff',
			is_active: true,
			is_visible: true
		});
		telegramMainButton.onClick(handleTelegramButtonClick);
	}, [totalPrice]);

	const handleModalClose = () => {
		handlePayButton();
		setModalOpen(false);
	};

	return (
		<>
			<Modal open={modalOpen} handleClose={handleModalClose}>
				<div className="order-success">
					Ваша заявка успешно отправлена
				</div>
			</Modal>
			<div className='cart-container'>
				<div className="cart-order-info">
					<div className="cart-title">
						<span className="cart-name">Ваш заказ</span>
						<span className="cart-edit-button" onClick={() => changePage('main')}>Редактировать</span>
					</div>
					<ul className="cart-order-items">
						{items.map(item => (
							<li key={item.id} className='cart-order-item'>
								<img className="cart-order-item-image" src={item.image} alt=""/>
								<div className="cart-order-item-title">{item.name} ({item.packaging}) × {item.count}</div>
								<div className="cart-order-item-price">{item.price}₸</div>
							</li>
						))}
					</ul>
					<div className="cart-total-price">
						<span>ИТОГО:</span>
						<span>{totalPrice}₸</span>
					</div>
				</div>
				<div className="cart-order-comment" id="phone-number-container">
					<InputMask
						mask="+7 999 999 99 99"
						alwaysShowMask={false}
						className="cart-order-telephone"
						placeholder="Телефон"
						name='phoneNumber'
						ref={phoneRef}
					/>
				</div>
				<div className="cart-order-comment">
					<input
						type="text"
						className="cart-order-comment-input"
						placeholder='Адрес доставки'
						name='address'
						ref={addressRef}
					/>
				</div>
				<div className="cart-order-comment">
					<input
						type="text"
						className="cart-order-comment-input"
						placeholder='Добавить комментарий...'
						name='comment'
						ref={commentRef}
					/>
					<div className="cart-order-comment-tip">
						Особые требования, детали, пожелания и тд.
					</div>
				</div>
			</div>
		</>
	)
};