import React from 'react';

export const Modal = ({ open, handleClose, children }) => {
	return open && (
			<div className="modal">
				<div className="mask" onClick={handleClose} />
				<div className="modal-content">
					{children}
				</div>
			</div>
		)
};