import { Main } from './pages/Main';
import { useCallback, useEffect, useState } from 'react';
import { Cart } from './pages/Cart';
import { items } from './consts/data';

function App() {
  const [orderItems, setOrderItems] = useState(items);
  const [cartItems, setCartItems] = useState([]);
  const [currentPage, setCurrentPage] = useState('main');

  const handlePayButton = () => {
    setOrderItems(orderItems.map(item => ({ ...item, count: 0 })));
    setCartItems([]);
    setCurrentPage('cart');
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const testApi = useCallback(async () => {
    await fetch('https://shop.nomad4ce.com/api/v1/teleshop/hello/');
  }, []);

  useEffect(() => {
    testApi();
  }, [testApi]);

  return (
    <div className="App">
      {currentPage === 'main' ?
        <Main items={orderItems} setItems={setOrderItems} setCartItems={setCartItems} setCurrentPage={setCurrentPage} /> :
        <Cart items={cartItems} handlePayButton={handlePayButton} changePage={changePage} />
      }
    </div>
  );
}

export default App;
