import React from 'react';

import classes from './SearchBar.module.scss';
import { items } from '../consts/data';

export const SearchBar = ({ setData }) => {
	const handleChange = e => {
		setData(items.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase())));
	}

	return (
		<div className={classes['search']}>
			<input className={classes['search__input']} type="text" onChange={handleChange} />
		</div>
	);
};