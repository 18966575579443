import { Card } from '../components/Card';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchBar } from '../components/SearchBar';
import { CATEGORIES, items as initialData } from '../consts/data';
import classes from './Main.module.scss';

export const Main = ({ items, setItems, setCartItems, setCurrentPage }) => {
	const totalQuantity = useMemo(() => items.reduce((a, b) => a + b.count, 0), [items]);
	const [categories, setCategories] = useState(CATEGORIES);

	const handleCartButton = useCallback(() => {
		setCartItems(items.filter(item => item.count > 0));
		setCurrentPage('cart');
	}, [items, setCartItems, setCurrentPage]);

	useEffect(() => {
		const telegramMainButton = window.Telegram.WebApp.MainButton;

		telegramMainButton.setParams({
			text: `Перейти в корзину (${totalQuantity})`,
			color: '#f9a818',
			text_color: '#fff',
			is_active: totalQuantity,
			is_visible: true
		});
		telegramMainButton.onClick(() => {
			handleCartButton();
		});
	}, [handleCartButton, totalQuantity]);

	const handleItemCountChange = (item, change) => {
		const index = items.indexOf(item);

		setItems([
			...items.slice(0, index),
			{
				...item,
				count: item.count + change
			},
			...items.slice(index + 1)
		])
	};

	const toggleCategory = category => {
		const index = categories.indexOf(category);
		setCategories([
			...categories.slice(0, index),
			{
				...category,
				open: !category.open
			},
			...categories.slice(index + 1)
		]);
	};

	return (
		<main className={classes['main']}>
			<SearchBar initialData={initialData} setData={setItems} />
			<div className={classes["main__categories"]}>
				{items.length ? (
					categories.map(category => items.find(item => item.category === category.id) && (
					<>
						<div className={classes["category"]} onClick={() => toggleCategory(category)}>
							<h2 className={classes["category__name"]}>{category.name}</h2>
							<span className={classes["category__icon"]}>{category.open ? '-' : '+'}</span>
						</div>
						<div className="container" key={category.id}>
							{items.map(item => category.open && item.category === category.id &&
								<Card key={item.id} item={item} handleItemCountChange={handleItemCountChange} />
							)}
						</div>
					</>
					))) : (
					<div>Не удалось найти сходств по поисковому фильтру...</div>
				)}
			</div>
		</main>
	);
}
